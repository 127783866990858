@charset "UTF-8";
/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 0 auto;
}

.alignright {
  float:right;
  margin: 0;
}

.alignleft {
  float: left;
  margin: 0;
}

a img.alignright {
  float: right;
  margin: 0;
}

a img.alignnone {
  margin: 0;
}

a img.alignleft {
  float: left;
  margin: 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 0;
}

.wp-caption.alignleft {
  margin: 0;
}

.wp-caption.alignright {
  margin: 0;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

// CUSTOM

.leftCol {
  width:70%;
  float:left;
}
.rightCol {
  width:30%;
  float:right;
}

.cmsContent {
  -webkit-text-size-adjust: none;
    -ms-text-size-adjust: none;
    word-wrap: break-word;
  ul,ol {
    padding-left: 20px;
    margin-bottom: 20px;
    li {
      a {
        color: $color_orange;
        text-decoration: underline;
        &:after {
          content: '▶︎';
          font-size: inherit;
          color: inherit;
          display: inline;
        }
      }
    }
  }
  ul {
    &[style*="list-style-type: circle;"] {
      li {
        list-style-type: circle;
      }
    }
    &[style*="list-style-type: square;"] {
      li {
        list-style-type: square;
      }
    }
    li {
      list-style: disc;
    }
  }
  ol {
    &[style*="list-style-type: lower-roman;"] {
      li {
        list-style-type: lower-roman;
      }
    }
    &[style*="list-style-type: lower-alpha;"] {
      li {
        list-style-type: lower-alpha;
      }
    }
    &[style*="list-style-type: lower-greek;"] {
      li {
        list-style-type: lower-greek;
      }
    }
    &[style*="list-style-type: upper-alpha;"] {
      li {
        list-style-type: upper-alpha;
      }
    }
    &[style*="list-style-type: upper-roman;"] {
      li {
        list-style-type: upper-roman;
      }
    }
    li {
      list-style-type: decimal;
    }
  }
  iframe {max-width: 100%!important;}
  img{
    max-width:100%;
    height:auto;
    max-height:100%;
    width: auto;
  }
  p {
    @include font(14,24.5);
    margin-bottom: 27px;
    a {
      color: $color_orange;
      text-decoration: underline;
      &:after {
        content: '▶︎';
        font-size: inherit;
        color: inherit;
        display: inline;
      }
      &.link-with-img {
        &:after {
          display: none;
        }
      }
    }
  }
  h1 {
    background: #333;
    color: #fff;
    @include font(18,30);
    font-weight: 700;
    padding: 10px 20px;
    margin: 12px 0 25px;
    .desktop.windows.ie &,
    .tablet.windows.ie & {
      padding-top: 12px;
      padding-bottom: 8px;
    }
    @include SP {
      padding: 8px 20px;
      margin-bottom: 17px;
    }
  }
  h2 {
    @include font(16,28);
    font-weight: 700;
    border-bottom: 1px solid #1a1a1a;
    padding: 0 0 3px;
    display: inline-block;
    margin: 7px 0 25px;
    @include SP {
      @include font(15);
      display: inline;
      padding-bottom: 5px;
      &:after {
        content: '';
        height: 25px;
        background: transparent;
        width: 100%;
        display: block;
      }
    }
  }
  h3 {
    @include font(16,28);
    border-left: 10px solid #333;
    padding: 0 0 0 18px;
    font-weight: 700;
    @include SP {
      @include font(15);
    }
  }
}
