@charset 'UTF-8';


@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/utility/_burger.scss";
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/utility/_flex.scss";
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/utility/_media-queries.scss";
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/utility/_mixin.scss";

@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/layout/_base.scss";
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/layout/_cmspages.scss";
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/layout/_footer.scss";
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/layout/_header.scss";

@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/libs/aos.scss";
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/libs/magnific-popup.scss";
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/libs/owl.carousel.scss";
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/libs/owl.theme.default.scss";
@import "/Users/VuongHuy/Website/vhost/kyohoucorp/src/scss/libs/simplebar.scss";
