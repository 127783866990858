@charset 'UTF-8';
.header {
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 1000;
  width: 100%;
  height: 50px;
  .container {
    @include flexbox();
    @include justify-content(space-between);
    height: 100%;
  }
  &__main {
    @include flexbox();
    @include align-items(center);
    @include flex( 0 0 auto);
    @include SP {
      width: 100%;
      @include justify-content(space-between);
    }
    &--logo {
      max-width: 188px;
      width: 100%;
      @include min-screen(768px) {
        max-width: 100px;
      }
      @include min-screen(992px) {
        max-width: 188px;
      }
      @include SP {
        max-width: 110px;
      }
      a {
        display: block;
        line-height: 0;
        font-size: 0;
      }
    }
    &--menu-btn {
      @include min-screen(768px) {
        display: none !important;
      }
      width: 60px;
      height: 50px;
      background: #fff;
      padding: 0;
      margin: 0;
      margin: 0 -20px 0 0;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      .burger-icon {
        @include burger(20px,1px,5px,#333,5px);
        &.is-active {
          // @include burger-to-cross;
        }
      }
    }
  }
  &__gnavi {
    width: 100%;
    @include min-screen(768px) {
      display: block !important;
    }
    @include SP {
      position: fixed;
      top: 50px;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.8);
      z-index: 2000;
      display: none;
      overflow-y: auto;
    }
    nav {
      @include flexbox();
      @include justify-content(flex-end);
      height: 100%;
      @include SP {
        display: block;
        height: 100%;
        padding: 0 40px 100px;
        overflow-y: auto;
      }
      @include ANDR {
        padding-left: 20px;
        padding-right: 20px;
      }
    }
    &--menu {
      @include flexbox();
      @include align-items(center);
      @include justify-content(flex-end);
      width: 100%;
      margin: 0 16px 0 0;
      @include SP {
        margin: 0;
        padding: 17px 0;
        @include flex-direction(column);
      }
      li {
        a {
          @include font(13,22.75);
          color: #1d2088;
          display: block;
          font-weight: 600;
          padding: 0 15px;
          @include min-screen(768px) {
            @include font(10);
            padding: 0 5px; 
          }
          @include min-screen(992px) {
            @include font(11);
            padding: 0 10px; 
          }
          @include min-screen(1300px) {
            @include font(13);
            padding: 0 15px; 
          }
          @include SP {
            color: #fff;
            padding: 10px 0;
            display: block;
            text-align: center;
          }
          &:hover,
          &.is-active {
            opacity: 1;
            color: #8f8f8f;
          }
        }
      }
    }
    &--btn-contact {
      max-width: 130px;
      width: 100%;
      @include SP {
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
      }
      a {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        height: 100%;
        @include font(13,22.75);
        color: #fff;
        font-weight: 600;
        position: relative;
        text-align: center;
        @include SP {
          @include font(12,22.75);
          padding: 14px;
        }
        &:after {
          content: '';
          background: #ff891e;
          width: calc(100% - 30px);
          height: 100%;
          position: absolute;
          @include skew(-30,0);
          top: 0;
          left: 0;
          margin: 0 15px;
          z-index: -1;
        }
      } 
    }
  }
}