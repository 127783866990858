@charset "UTF-8";
/*-----------------------------------------------------------
clearfix
------------------------------------------------------------*/
.clearfix:after {
  content: "";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
  line-height: 0;
}
.clearfix {display: inline-block;}
/* exlude MacIE5 \*/
* html .clearfix {height: 1%}
.clearfix {display: block;}
/* end MacIE5 */
/*-----------------------------------------------------------
reset
------------------------------------------------------------*/
p {
  margin: 0;
  padding: 0;
}
ol, ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
input, textarea {
  margin: 0;
  font-size: 100%;
  resize: none;
}
table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 100%;
}
dl, dt, dd, th, td {
  margin: 0;
  padding: 0;
}
h1, h2, h3, h4, h5, h6 {
  /*font-size: 100%;*/
  font-weight: normal;
  margin: 0;
  padding: 0;
}
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video, dialog {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
img {
  margin: 0;
  padding: 0;
  vertical-align: middle;
  border: 0;
  max-width:100%;
  height:auto;
}
table img {
  margin: 0;
  padding: 0;
  vertical-align: middle;
  border: 0;
}
article, aside, dialog, figure, footer, header, hgroup, nav, section {display: block;}
nav ul {list-style: none;}
*, *:before, *:after {
  @include add_prefix(box-sizing, border-box);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
*:focus {outline: none!important;}
label, select, button {cursor: pointer;}

// Remove select appearance for IE
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  select::-ms-expand {
    display: none;
  }
}
@media screen\0 {
  select::-ms-expand {
    display: none;
  }
}

/*-----------------------------------------------------------
Link
------------------------------------------------------------*/
a {
  outline: none;
  transition:0.3s;
  color: $colortext;
  background-color:transparent;
  -webkit-touch-callout:none;
  -webkit-tap-highlight-color: transparent;
  @include PC {
    &:hover {
      text-decoration: none;
      opacity: 0.6;
    }
  }
}
a:link, a:visited, a:active {
  text-decoration: none;
}
/*-----------------------------------------------------------
FONT family
------------------------------------------------------------*/
.ffN {@include ffN;}
.ffM {@include ffM;}
.ffYG {@include ffYG;}
.ffYM {@include ffYM;}

/* Text meant only for screen readers. */
.screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden;
}

.screen-reader-text:focus {
  background-color: #f1f1f1;
  border-radius: 3px;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
  clip: auto !important;
  color: #21759b;
  display: block;
  font-size: 14px;
  font-size: 0.875rem;
  font-weight: bold;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000; /* Above WP toolbar. */
}

/*-----------------------------------------------------------
Body
-------------------------------------------------------------*/
html {
  font-size: 62.5%;
  @include add_prefix(text-size-adjust, 100%);
  // @include add_prefix(font-feature-settings, "palt");
}
body {
  @include ffN;
  @include font(14,28);
  width: 100%;
  color: $colortext;
  margin: 0;
  padding: 0;
  letter-spacing: 0;
  height: 100%;
  word-break: break-word;
}
/*-----------------------------------------------------------
Container
------------------------------------------------------------*/
.container {
  padding: 0 20px;
  margin: 0 auto;
  max-width: 1210px;
  width: 100%;
  // @include min-screen(768px) {
  //   width: 720px;
  // }
  // @include min-screen(992px) {
  //   width: 940px;
  // }
  // @include min-screen(1300px) {
  //   width: 1210px;
  // }
}
.inner {
  max-width: 980px;
  margin: 0 auto;
}
#wrap {
  overflow: hidden;
  padding: 50px 0 0;
}
/*-----------------------------------------------------------
common
------------------------------------------------------------*/
.sp, .SP {
  @include min-screen(768px) {display: none!important;}
}

.pc, .PC {
  @include max-screen(767px) {display: none!important;}
}

.text-shadow {
  text-shadow: 0px 0px 6px rgba(0,0,0,0.8);
  -webkit-text-shadow: 0px 0px 6px rgba(0,0,0,0.8);
  -moz-text-shadow: 0px 0px 6px rgba(0,0,0,0.8);
}
.cm-title {
  text-align: center;
  color: #1a1a1a;
  margin: 0 0 25px;
  .name-jp {
    @include font(28,49);
    font-weight: 700;
    display: block;
    @include SP {
      @include font(23,38)
    }
    @include ANDR {
      @include font(20);
    }
  }
  .name-en {
    @include font(14,28);
    display: block;
    @include SP {
      @include font(12,14);
    }
  }
  &--white {
    .name-jp,
    .name-en {
      color: #fff;
    }
  }
}
.cm-txt {
  margin: 0 0 32px;
  p {
    @include font(14,28);
    @include SP {
      @include font(14,24.5);
    }
  }
  &--white {
    p {
      color: #fff;
    }
  }
}
.cm-button {
  max-width: 330px;
  width: 100%;
  background: transparent;
  border: none;
  outline: 0;
  @include font(14,28);
  color: #fff;
  text-align: center;
  padding: 11px;
  position: relative;
  display: block;
  margin: 0 auto;
  z-index: 1;
  .desktop.windows.ie &,
  .tablet.windows.ie & {
    padding: 13px 11px 9px;
  }
  @include SP {
    max-width: 266px;
    @include font(16,28);
  }
  &:after {
    content: '';
    background: $color_blue;
    width: calc(100% - 30px);
    height: 100%;
    position: absolute;
    @include skew(-30,0);
    @include transition_c(.3s);
    top: 0;
    left: 0;
    margin: 0 15px;
    z-index: -1;
  }
  &:hover {
    opacity: 1;
    &:after {
      opacity: .4;
    }
  }
  &--invert {
    &:after {
      border: 1px solid #fff;
    }
    &:hover {
      color: $color_blue;
      &:after {
        background: #fff;
        opacity: 1;
      }
    }
  }
  &.color-yellow {
    &:after {
      background: $color_orange;
    }
  }
  &.color-blue {
    &:after {
      background: #0068b1;
    }
  }
}

.cm-button-icon {
  @include font(14,28);
  font-weight: 700;
  display: inline-block;
  padding: 0 0 0 45px;
  background: url('../img/common/icon/ico_icon_off.svg') left center/36px 14px no-repeat;
  .desktop.mac.safari & {
    @include transition_c(0s);
  }
  .desktop.windows.ie &,
  .tablet.windows.ie & {
    background-position: left top 6px;
  }
  @include SP {
    // @include font(16,28);
    // background-size: 42px 16px;
    // padding-left: 55px;
  }
  @include ANDR {
    @include font(12,24);
    background-size: 24px 10px;
  }
  &--ico-white {
    color: #fff;
    background-image: url('../img/common/icon/ico_icon_w.svg');
  }
  &--effect {
    &:hover {
      opacity: 1;
      color: $color_blue;
      background-image: url('../img/common/icon/ico_icon_on.svg');
    }
  }
  &--large {
    @include font(16,28);
    padding: 0 0 0 50px;
    background: url('../img/common/icon/ico_icon_off.svg') left center/40px 16px no-repeat;
    .desktop.windows.ie &,
    .tablet.windows.ie & {
      background-position: left top 4px;
    }
    @include SP {
      padding-left: 55px;
    }
  }
}

.cm-mainvisual {
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  padding: 20px;
  height: 440px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  @include SP {
    height: 150px;
  }
}

.cm-breadcrum {
  padding: 12px 0 50px;
  @include SP {
    padding: 6px 0 28px;
  }
  ul {
    line-height: 0;
    li {
      display: inline;
      @include font(12,20);
      position: relative;
      &:not(:last-child) {
        padding: 0 10px 0 0;
        &:after {
          content: '>';
          @include font(12,12);
          margin-left: 15px;
          position: relative;
          top: -1px;
          .desktop.windows.chrome & {
            top: 0px;
          }
          .desktop.windows.ie &,
          .tablet.windows.ie &,
          .desktop.windows.firefox &,
          .desktop.windows.windows8.chrome & {
            top: 2px;
          }
        }
      }
      a {
        @include font(12,20);
        display: inline;
      }
    }
  }
}

.cm-list-brand {
  @include flexbox();
  @include flex-wrap(wrap);
  @include justify-content(space-between);
  overflow: hidden;
  margin: 0 -26px;
  padding: 30px 0 0;
  @include SP {
    padding-top: 23px;
  }
  li {
    width: 50%;
    padding: 10px 26px;
    @include SP {
      width: 100%;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    .list-brand-wrap {
      background: #fff;
      @include flexbox();
      @include flex-direction(column);
      height: 100%;
      @include box-shadow(1px,1px,5px,rgba(0,0,0,.3));
      position: relative;
    }
    .list-brand-img {
      background: #333;
      text-align: center;
      height: 80px;
      @include flexbox();
      @include align-items(center);
      @include justify-content(center);
      padding: 10px 15px;
      @include SP {
        height: 48px;
        img {
          max-height: 23px;
          max-width: 100%;
          width: 100%;
        }
      }
    }
    .list-brand-info {
      padding: 20px 10px 45px;
      min-height: 181px;
      @include flexbox();
      @include flex-direction(column);
      &.align-item-center {
        @include justify-content(center);
        @include align-items(center);
      }
      @include SP {
        min-height: 170px;
        padding-top: 35px;
        padding-bottom: 32px;
        @include justify-content(center);
        @include align-items(center);
      }
      .inf-description {
        text-align: center;
        margin: 0 0 16px;
        @include SP {
          margin-bottom: 5px;
          line-height: 1.75em;
        }
        @include ANDR {
          @include font(12);
        }
      }
      .inf-cate {
        text-align: center;
        color: #808080;
        margin: 0 0 16px;
        @include SP {
          margin-bottom: 5px;
          line-height: 1.75em;
        }
        @include ANDR {
          @include font(12);
        }
      }
      .inf-btn {
        position: absolute;
        bottom: 18px;
        right: 20px;
        @include SP {
          @include font(14);
          background-size: 32px 13px;
          padding-left: 35px;
          position: static;
        }
      }
    }
  }
}


.cm-brand-logo {
  @include flexbox();
  @include flex-wrap(wrap);
  overflow: hidden;
  margin: 0 -10px;
  li {
    width: 15%;
    padding: 28px 10px;
    @include flexbox();
    @include align-items(flex-end);
    overflow: hidden;
    text-align: center;
    &:nth-of-type(7n) {
      width: 10%;
      @include min-screen(768px) {
        width: 20%;
      }
      @include min-screen(992px) {
        width: 10%;
      }
      @include SP {
        width: 33.333%;
      }
    }
    @include min-screen(768px) {
      width: 20%;
    }
    @include min-screen(992px) {
      width: 15%;
    }
    @include SP {
      width: 33.333%;
      padding: 15px 10px;
    }
    img {
      max-width: 100px;
      width: 100%;
    }
  }
}

.cm-article-blocks {
  margin: -35px 0 35px;
  @include SP {
    margin-top: 10px;
  }
  .article-item {
    border-bottom: 1px solid #aeaeae;
    padding: 35px 0 33px;
    &-date {
      @include font(14,24);
      margin-bottom: 6px;
    }
    &-tit {
      margin-bottom: 15px;
      a {
        display: inline-block;
        @include font(16,28);
        font-weight: 700;
      }
    }
    &-description {
      margin-bottom: 23px;
    }
  }
}

.cm-article-products {
  @include flexbox();
  @include flex-wrap(wrap);
  overflow: hidden;
  margin: 0 -9px;
  @include SP {
    margin: 0 -7px;
    padding: 50px 0 0;
  }
  .product-item {
    width: 33.333%;
    padding: 0 9px;
    margin-bottom: 73px;
    @include flexbox();
    @include flex-direction(column);
    .desktop.windows.ie &,
    .tablet.windows.ie & {
       
    }
    @include SP {
      width: 50%;
      padding: 0 7px;
      margin-bottom: 40px;
    }
    &-info {
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(space-between);
      height: 100%;
    }
    &-img {
      margin-bottom: 15px;
      border: 1px solid #e1e1e1;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      @include aspect-ratio(260,195);
      display: block;
      .desktop.windows.ie &,
      .tablet.windows.ie & {
        min-height: 196px;
      }
      @include SP {
        margin-bottom: 7px;
      }
    }
    &-cate-brand {
      margin-bottom: 8px;
      @include SP {
        margin-bottom: 14px;
      }
      span {
        @include font(12,14);
        font-weight: 700;
        color: #fff;
        display: inline-block;
        margin: 0 10px 10px 0;
        padding: 8px 12px;
        @include SP {
          @include font(10);
          padding: 6px 9px;
          margin-bottom: 5px;
        }
        @include ANDR {
          @include font(8);
          margin: 0 5px 5px 0;
        }
        &.brand {
          background: #000;
        }
        &.cat {
          background: #949494;
        }
      }
    }
    &-date {
      @include font(12,15);
      @include SP {
        @include font(10);
        margin-bottom: 4px;
      }
    }
    &-tit {
      font-weight: 700;
      margin-bottom: 29px;
      a {
        width: 100%;
        @include clamp(2);
        .desktop.windows.ie &,
        .tablet.windows.ie & {
          @include excerpt(14px,2,2,#fff);
        }
        @include SP {
          @include font(14,24.5);
          @include clamp(3);
        }
      }
    }
    .cm-button-icon {
      @include SP {
        @include font(14,28);
        padding-left: 50px;
      }
      @include ANDR {
        @include font(12);
        padding-left: 35px;
      }
    }
  }
}

.cm-stock-confirm {
  margin-top: 95px;
  @include SP {
    margin-top: 45px;
  }
  .stock-tit {
    background: #333;
    color: #fff;
    text-align: center;
    @include font(20,28);
    padding: 9px 0 13px;
    margin: 0 0 37px;
    .desktop.windows.ie &,
    .tablet.windows.ie & {
      padding: 12px 0 10px;
    }
    @include SP {
      margin: 0 -20px 28px;
      @include font(18,20);
      padding: 14px 0 17px;
    }
  }
  .stock-list {
    margin: 0 -60px;
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(center);
    overflow: hidden;
    @include SP {
      margin: 0;
      padding: 0 20px;
    }
    &-item {
      width: 50%;
      padding: 0 60px;
      margin: 0 0 35px;
      @include SP {
        width: 100%;
        padding: 0;
        margin-bottom: 40px;
      }
      &.item-qr {
        padding: 0 20px;
        margin: 0;
        @include SP {
          padding: 8px 0 0;
        }
      }
      .tit {
        @include font(20,24);
        border-bottom: 1px solid #666;
        padding: 0 0 8px;
        margin: 0 0 15px;
        @include SP {
          @include font(17,20);
          padding-bottom: 10px;
        }
      }
      .box-tel {
        a {
          display: inline-block;
          color: $color_orange;
          @include font(41,41);
          margin: 0 0 10px;
          @include min-screen(768px) {
            @include font(30);
          }
          @include min-screen(992px) {
            @include font(41);
          }
          @include SP {
            @include font(40);
            margin-bottom: 6px;
          }
          @include ANDR {
            @include font(30);
          }
        }
        .time {
          @include font(16,24.7);
          padding-left: 94px;
          position: relative;
          @include min-screen(768px) {
            @include font(14);
          }
          @include min-screen(992px) {
            @include font(16);
          }
          @include SP {
            @include font(14,23);
            padding-left: 80px;
          }
          @include ANDR {
            @include font(12);
          }
          &:before {
            content: '受付時間';
            @include font(16,24.7);
            display: inline-block;
            position: absolute;
            left: 0;
            top: 0;
            @include SP {
              @include font(14,23);
            }
            @include ANDR {
              @include font(12);
            }
          }
        }
      }
      .box-btn {
        p {
          margin: 0 0 18px;
          @include SP {
            margin-bottom: 12px;
            margin-top: -10px;
          }
          @include ANDR {
            @include font(13);
          }
        }
        .cm-button {
          max-width: 312px;
          margin: 0;
          @include font(15,28);
          @include min-screen(768px) {
            max-width: 240px;
          }
          @include min-screen(992px) {
            max-width: 312px;
          }
          @include SP {
            max-width: 100%;
            @include font(14)
          }
        }
      }
      .box-qr {
        @include flexbox();
        .img {
          width: 125px;
          @include flex( 0 0 auto);
          @include ANDR {
            width: 90px;
          }
        }
        .text {
          @include font(16,21);
          padding: 5px 0 0 15px;
          @include SP {
            @include font(14);
            padding: 2px 0 0 10px;
          }
        }
      }
    }
  }
}

.cm-cate-product-list {
  @include flexbox();
  @include flex-wrap(wrap);
  overflow: hidden;
  margin: 0 -10px 65px;
  @include SP {
    margin: 0 -5px 15px;
    padding: 65px 0 0;
  }
  .cate-pro-item {
    width: 50%;
    padding: 10px;
    @include SP {
      padding: 0 5px;
      margin-bottom: 20px;
    }
    a {
      @include flexbox();
      @include flex-direction(column);
      @include justify-content(space-between);
      border: 1px solid #666;
      padding: 10px 15px ;
      height: 100%;
      position: relative;
      @include SP {
        padding: 5px 10px;
      }
    }
    &-brand {
      line-height: 0;
      font-size: 0;
      span {
        @include font(12,14);
        font-weight: 700;
        color: #fff;
        display: inline-block;
        margin: 0 10px 10px 0;
        padding: 8px;
        min-width: 80px;
        text-align: center;
        @include SP {
          @include font(10);
          padding: 6px;
          margin-bottom: 5px;
          margin-right: 5px;
          min-width: auto;
        }
        @include ANDR {
          @include font(8);
        }
        &.main-brand {
          background: #000;
          @include SP {
            min-width: 65px;
          }
          @include ANDR {
            min-width: 40px;
          }
        }
        &.sub-brand {
          background: #949494;
        }
      }
    }
    &-img {
      margin-bottom: 18px;
      .desktop.windows.ie &,
      .tablet.windows.ie & {
        // min-height: 276px;
      }
      @include SP {
        height: auto;
      }
      span {
        display: block;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        @include aspect-ratio(370,278);
      }
    }
    .info-wrap {

    }
    &-info {
      padding: 0 5px;
      @include SP {
        padding: 0;
      }
      .tit {
        @include font(16,28);
        font-weight: 700;
        @include SP {
          @include font(14,18);
          margin-bottom: 10px;
        }
      }
      .price {
        @include font(12,16);
        margin-bottom: 10px;
        @include SP {
          margin-bottom: 12px;
        }
      }
    }
    .cm-button-icon {
      @include align-self(flex-end);
      @include SP {
        @include align-self(flex-start);
      }
      @include ANDR {
        @include font(12);
        padding-left: 35px;
      }
    }
  }
  &.col-3 {
    .cate-pro-item {
      width: 33.333%;
      @include SP {
        width: 50%;
      }
    }
  }
}

.cm-nav {
  ul {
    @include flexbox();
    @include flex-wrap(wrap);
    @include justify-content(center);
    overflow: hidden;
    margin: 0 -8px;
    @include SP {
      margin: 0 -5px;
    }
    li {
      padding: 8px;
      @include SP {
        padding: 7px 5px;
      }
      a {
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        @include font(18,28);
        font-weight: 700;
        color: #fff;
        background: #0068b1;
        padding: 16px 10px;
        @include SP {
          @include font(16);
          padding: 9px 10px;
        }
      }
    }
  }
  &.col-6 {
    ul {
      li {
        width: 16.666%;
        @include SP {
          width: 33.333%;
        }
      }
    }
  }
  &.col-5 {
    ul {
      li {
        width: 20%;
      }
    }
  }
}

.global-pagenavi {
  text-align: center;
  a,span {
    @include font(16,28);
    display: inline-block;
    margin: 0 7px;
    position: relative;
  }
  .page {
    &:after {
      content: '・';
      position: absolute;
      left: -15px;
      top: 0;
      color: $colortext;
      display: inline-block;
      .desktop.windows.ie &,
      .tablet.windows.ie & {
        top: 2px;
      }
    }
    &:hover {
      color: $color_light_blue;
      text-decoration: underline;
    }
    &.hide-dot {
      &:after {
        display: none;
      }
    }
  }
  a[title="Page 1"] {
    &:after {
      display: none;
    }
  }
  span.extend {
    margin: 0;
    position: relative;
    &:before {
      content: '';
      background: #fff;
      width: 5px;
      height: 100%;
      position: absolute;
      right: -3px;
      top: 0;
      z-index: 1;
      pointer-events: none;
    }
  }
  span.current {
    color: $color_light_blue;
    text-decoration: underline;
    &:after {
      content: '・';
      position: absolute;
      left: -15px;
      top: 0;
      color: $colortext;
      display: inline-block;
    }
    &:first-child {
      &:after {
        display: none;
      }
    }
  }
}

.page-blocks-have-side {
  @include flexbox();
  @include flex-wrap(wrap);
  @include justify-content(space-between);
  padding: 20px 0 0;
  @include SP {
    padding: 5px 0 0;
  }
  .global-sidebar {
    width: 27.35%;
    @include SP {
      width: 100%;
      padding: 0 20px;
    }
    .global-sidebar-box {
      margin-bottom: 30px;
      @include SP {
        margin-bottom: 30px;
      }
    }
    .sidebar-tit {
      @include font(18,30);
      font-weight: 700;
      border-bottom: 1px solid $colortext;
      @include SP {
        @include font(17,29.5);
      }
    }
    .sidebar-archive-date {
      margin-top: 35px;
      margin-bottom: -15px;
      @include SP {
        margin-top: 24px;
      }
      li {
        &:not(:last-child) {
          margin: 0 0 11px;
          @include SP {
            margin-bottom: 4px;
          }
        }
        a {
          display: inline-block;
          @include font(16,28);
          @include SP {
            @include font(14,26);
          }
        }
      }
    }
    .sidebar-box-button {
      @include flexbox();
      @include justify-content(center);
      overflow: hidden;
      padding-top: 28px;
      @include SP {
        padding-top: 10px;
      }
      &.col-2 {
        margin: 0 -10px;
        @include min-screen(768px) {
          @include flex-wrap(wrap);
        }
        @include min-screen(992px) {
          @include flex-wrap(inherit);
        }
        .sidebar-btn {
          width: 50%;
          margin: 0 10px;
          @include min-screen(768px) {
            width: 100%;
            margin: 10px;
          }
          @include min-screen(992px) {
            width: 50%;
            margin: 0 10px;
          }
        }
      }
    }
    .sidebar-btn {
      color: $colortext;
      @include font(16,18);
      display: block;
      text-align: center;
      @include border-radius(6px);
      padding: 16px 5px;
      width: 100%;
      font-weight: 700;
      border: none;
      outline: 0;
      @include transition_c(.3s);
      &:hover {
        opacity: .6;
      }
      .desktop.windows.ie &,
      .tablet.windows.ie & {
        padding: 17px 5px 15px;
      }
      @include SP {
        @include font(15,18);
        @include border-radius(3px);
      }
      &.primary {
        background: #c7c7c7;
      }
      &.secondary {
        background: $color_blue;
        color: #fff;
      }
    }
    .box-checkbox {
      margin: 20px 0 0;
      @include SP {
        margin-top: 28px;
      }
      li {
        &:not(:last-child) {
          margin: 0 0 20px;
          @include SP {
            margin-bottom: 27px;
          }
        }
        padding: 0 0 0 10px;
      }
      .input-chk {
        display: none;
      }
      .input-label {
        @include font(14,22);
        display: block;
        position: relative;
        padding: 0 0 0 40px;
        &:before {
          content: '';
          width: 20px;
          height: 20px;
          border: 1px solid $colortext;
          display: block;
          position: absolute;
          left: 0;
          top: 0;
        }
        &:after {
          content: '';
          width: 10px;
          height: 10px;
          background: $color_blue;
          position: absolute;
          top: 5px;
          left: 5px;
          display: none;
        }
      }
      .input-chk:checked + label.input-label {
        &:after {
          display: block;
        }
      }
    }
    .box-accordion {
      padding: 0 0 0 15px;
      margin: 15px 0 5px;
      .name-cate {
        position: relative;
        padding: 0 0 0 25px;
        display: block;
        &:before {
          content: '>';
          @include font(17,28);
          position: absolute;
          left: 0;
          top: 0;
          @include rotate(90);
        }
      }
      .box-checkbox {
        display: none;
        padding: 0 0 0 15px;
        margin-top: 16px;
        margin-bottom: 15px;
      }
    }
    .box-checkbox-inline {
      @include flexbox();
      @include flex-wrap(wrap);
      padding: 0 5px;
      max-width: 230px;
      margin-top: 15px;
      li {
        margin: 5px;
      }
      .input-chk {
        display: none;
      }
      .input-label {
        width: 34px;
        height: 34px;
        @include font(14,22);
        display: block;
        position: relative;
        font-weight: 700;
        border: 1px solid $colortext;
        @include flexbox();
        @include align-items(center);
        @include justify-content(center);
        .desktop.mac.safari & {
          padding-top: 3px;
        }
      }
      .input-chk:checked + label.input-label {
        background: $color_blue;
        color: #fff;
      }
    }
  }
  .global-content {
    width: 70.086%;
    @include SP {
      width: 100%;
    }
    .no-results {
      @include SP {
        text-align: center;
        margin: 75px 0 30px;
      }
    }
    &.full {
      width: 100%;
    }
    &-detail {
      @include SP {
        margin-top: 45px;
      }
      .detail-status {
        margin-bottom: 20px;
        .cate-brand {
          margin-right: 6px;
          display: inline-block;
          @include SP {
            margin-bottom: 15px;
            margin-right: 0;
          }
          span {
            @include font(12,14);
            font-weight: 700;
            color: #fff;
            display: inline-block;
            margin: 0 10px 10px 0;
            padding: 8px 12px;
            @include SP {
              @include font(14);
              padding: 9px 18px;
              margin-bottom: 5px;
            }
            &.brand {
              background: #000;
            }
            &.cat {
              background: #949494;
            }
          }
        }
        .date {
          line-height: 1em;
          vertical-align: bottom;
          display: inline-block;
          @include SP {
            display: block;
          }
        }
      }
      .box-address {
        border-top: 1px solid #000;
        padding: 20px 0 0;
        margin: 0 0 60px;
        @include SP {
          padding-top: 25px;
          margin-bottom: 45px;
        }
        .address-description {
          @include font(16,28);
          font-weight: 700;
          @include SP {
            @include font(14,24.5);
            font-weight: 400;
          }
        }
        .address-map {
          height: 300px;
          margin: 60px 0 0;
          iframe {
            width: 100% !important;
            height: 100% !important;
          }
          @include SP {
            height: 150px;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
.sidebar-banner {
  li {
    margin-top: 30px;
    a {
      display: block;
      figure {
        position: relative;
        overflow: hidden;
        z-index: 1;
        &:before {
          content: '';
          width: 100%;
          height: 100%;
          position: absolute;
          left: 0;
          top: 0;
          background: rgba(26,26,26,.5);
        }
        figcaption {
          position: absolute;
          width: 100%;
          height: 100%;
          text-align: center;
          top: 0;
          left: 0;
          @include flexbox();
          padding: 10px;
          @include align-items(center);
          @include justify-content(center);
          color: #fff;
          @include font(18,28,0,700);
          z-index: 1;
        }
      }
    }
  }
}

.sub-mainvisual {
  height: 250px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  @include flexbox();
  @include align-items(center);
  @include justify-content(center);
  @include SP {
    height: 125px;
  }
  .cm-title {
    color: #fff;
  }
}
