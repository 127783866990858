@charset 'UTF-8';
#to-top {
  background: $color_blue;
  height: 50px;
  @include SP {
    height: 40px;
    margin: 0 -20px;
  }
  a {
    @include flexbox();
    height: 100%;
    @include align-items(center);
    @include justify-content(center);
    color: #fff;
    text-transform: uppercase;
    @include font(16,28);
    span {
      display: inline-block;
      background: url('../img/common/icon/ico_up.svg') left center/14px 6px no-repeat;
      padding: 0 0 0 22px;
    }
  }
}
.footer {
	background: #e8e8e8;
  @include SP {
    padding: 0 20px;
  }
  .subpage & {
    margin-top: 95px;
    @include SP {
      margin-top: 72px;
    }
  }
  &__main {
    @include flexbox();
    @include justify-content(space-between);
    padding: 40px 0 15px;
    border-bottom: 1px solid #1a1a1a;
    @include SP {
      @include flex-direction(column);
      padding: 25px 0 30px;
    }
    &--info {
      @include flex(1);
      .f-logo {
        margin: 0 0 20px;
        @include SP {
          text-align: center;
        }
        a {
          display: block;
          img {
            max-width: 230px;
            width: 100%;
            @include SP {
              max-width: 200px;
            }
          }
        }
      }
      .f-sitemap {
        padding: 0 40px 0 0;
        @include SP {
          display: none;
        }
        .links {
          line-height: 0;
          font-size: 0;
          li {
            display: inline-block;
            padding: 16px 40px 16px 0;
            a {
              @include font(13,22.75);
              font-weight: 700;
              display: block;
              &:hover,
              &.is-active {
                color: $color_light_blue;
              }
            }
          }
        }
      }
    }
    &--social {
      width: 312px;
      @include flex( 0 0 auto);
      @include SP {
        width: 100%;
        border-top: 1px solid #1a1a1a;
        padding-top: 35px;
      }
      .social-ttl {
        max-width: 312px;
        font-weight: 600;
        width: 100%;
        margin: 0 0 15px;
        @include SP {
          display: none;
        }
      }
      .list-social {
        @include flexbox();
        overflow: hidden;
        margin: 0 -10px;
        padding: 0 35px;
        @include SP {
          padding: 0;
          max-width: 220px;
          margin: 0 auto;
        }
        .list-item {
          width: 50%;
          padding: 0 10px;
          .tit {
            @include font(13,22.75);
            font-weight: 700;
            text-align: center;
            border-bottom: 1px solid #1a1a1a;
            padding: 0 0 2px;
            margin: 0 0 10px;
            @include SP {
              @include font(12);
            }
          }
          ul {
            @include flexbox();
            @include justify-content(space-between);
            li {
              a {
                @include border-radius(100%);
                display: block;
                background: #1a1a1a;
                img {
                  @include SP {
                    width: 38px;
                    height: 38px;
                  }
                }
              }
            }
          }
          &.kyoho {
            .tit {
              color: $color_light_blue;
              border-bottom-color: $color_light_blue;
            }
            ul {
              li {
                a {
                  background: $color_light_blue;
                }
              }
            }
          }
        }
      }
    }
  }
  &__logo {
    .list-logo {
      @include flexbox();
      @include justify-content(center);
      padding: 40px 0;
      border-bottom: 1px solid #1a1a1a;
      @include SP {
        padding: 5px 0;
        @include flex-direction(column);
        @include align-items(center);
        border-bottom-color: transparent;
      }
      li {
        padding: 0 16px;
        @include SP {
          margin: 10px 0;
        }
        a {
          display: block;
          img {
            @include SP {
              height: 30px;
            }
          }
        }
      }
    }
  }
  &__copyright {
    text-align: center;
    @include font(10,10);
    padding: 20px 0;
    @include SP {
      margin: 0 -20px;
      padding: 7px 0;
      text-transform: uppercase;
    }
  }
}
